var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { createElement as _createElement } from "react";
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/jsx-props-no-spreading */
import styled from '@emotion/styled';
import MuiChip from '@mui/material/Chip';
import { forwardRef, useEffect, useState, } from 'react';
import isPropValid from '@emotion/is-prop-valid';
import { useTheme } from '@emotion/react';
import Checkbox from '@shared/ui/inputs/Checkbox';
import { ReactComponent as ErrorIcon } from '@icons/wolfkit-solid/alert-circle-solid.svg';
import IconText from '@shared/ui/icons/IconText';
import Icon from '@shared/ui/icons/Icon';
import { defineBorderColor, defineBoxShadowStyles, getSelectButtonStyles } from './styles';
import { defineComponentValue, defineInitValues, updateOptionsState } from './utils';
const ItemComponent = styled(MuiChip, {
    shouldForwardProp: propName => propName !== 'itemVariant' &&
        propName !== 'multiple' && propName !== 'fullWidth' && propName !== 'hasError',
})(props => ({
    '&': {
        width: props.fullWidth ? '100%' : 'unset',
        padding: getSelectButtonStyles(props.theme)[props.itemVariant].padding,
        borderRadius: getSelectButtonStyles(props.theme)[props.itemVariant].borderRadius,
        height: 'auto',
        borderWidth: '1px',
        borderStyle: 'solid',
        backgroundColor: props.selected ?
            getSelectButtonStyles(props.theme)[props.itemVariant].backgroundColorSelected :
            getSelectButtonStyles(props.theme)[props.itemVariant].backgroundColor,
        fontSize: 16,
        userSelect: getSelectButtonStyles(props.theme)[props.itemVariant].userSelect,
        border: defineBorderColor({
            theme: props.theme,
            hasError: props.hasError,
            itemVariant: props.itemVariant,
            selected: props.selected,
        }),
        cursor: props.selected && !props.multiple ? 'default' : 'pointer',
        transition: 'all cubic-bezier(0.4, 0, 0.2, 1) 250ms',
        ':hover': {
            borderColor: getSelectButtonStyles(props.theme)[props.itemVariant].borderColorHover,
            backgroundColor: props.selected ?
                getSelectButtonStyles(props.theme)[props.itemVariant].backgroundColorSelected :
                getSelectButtonStyles(props.theme)[props.itemVariant].backgroundColorHover,
            transitionDuration: '250ms',
            '.MuiFormControlLabel-root': {
                margin: 0,
                rect: {
                    stroke: '#C1C8CD',
                },
            },
        },
        ':active': {
            outline: getSelectButtonStyles(props.theme)[props.itemVariant].outlineActive,
            boxShadow: defineBoxShadowStyles({
                theme: props.theme,
                hasError: props.hasError,
                itemVariant: props.itemVariant,
                selected: props.selected,
            }),
            '.MuiFormControlLabel-root': {
                margin: 0,
                svg: {
                    borderRadius: 6,
                    boxShadow: '0px 0px 0px 3px rgba(62, 99, 221, 0.20)',
                },
            },
        },
    },
    '&.Mui-disabled': {
        border: getSelectButtonStyles(props.theme)[props.itemVariant].borderDisabled,
        opacity: getSelectButtonStyles(props.theme)[props.itemVariant].opacityDisabled,
    },
    '& .MuiChip-icon': {
        margin: 0,
    },
    '& .MuiChip-deleteIcon': {
        marginLeft: props.theme.spacing_sizes.s,
        cursor: props.selected ? 'pointer' : 'default',
        color: props.selected ?
            getSelectButtonStyles(props.theme)[props.itemVariant].colorSelected :
            getSelectButtonStyles(props.theme)[props.itemVariant].color,
        fontSize: 16,
        ':hover': {
            color: props.selected ?
                getSelectButtonStyles(props.theme)[props.itemVariant].colorSelected :
                getSelectButtonStyles(props.theme)[props.itemVariant].color,
        },
    },
    '& .MuiChip-label': {
        width: '100%',
        padding: '0',
        color: props.selected ?
            getSelectButtonStyles(props.theme)[props.itemVariant].colorSelected :
            getSelectButtonStyles(props.theme)[props.itemVariant].color,
    },
}));
const ErrorText = styled(IconText)(props => ({
    color: props.theme.palette.error.main,
}));
const Item = (_a) => {
    var { onClick = undefined, onDelete = undefined, element = undefined, itemVariant, value, showDeleteBtn = false, multiple = false, selected = undefined, hasError = false, errorText = undefined } = _a, props = __rest(_a, ["onClick", "onDelete", "element", "itemVariant", "value", "showDeleteBtn", "multiple", "selected", "hasError", "errorText"]);
    const theme = useTheme();
    const handleItemClick = () => {
        if (multiple) {
            if (onClick && !props.disabled) {
                onClick(value);
            }
        }
        else if (onClick && !selected && !props.disabled) {
            onClick(value);
        }
    };
    const handleDelete = () => {
        if (onDelete && selected && !props.disabled) {
            onDelete(value);
        }
    };
    return (_jsxs(_Fragment, { children: [_createElement(ItemComponent, Object.assign({}, props, { key: `item-${value}`, itemVariant: itemVariant, multiple: multiple, icon: itemVariant === 'with-checkox' ? (_jsx(Checkbox, { checked: Boolean(selected), disabled: props.disabled })) : undefined, label: element, selected: selected, 
                // onClick doesn't fires which clicking on deleteIcon element
                onDelete: showDeleteBtn && selected ? handleDelete : undefined, hasError: hasError, onClick: handleItemClick })), hasError && (_jsx(ErrorText, { text: errorText, IconComponent: (_jsx(Icon, { IconComponent: ErrorIcon, color: theme.palette.error.main, size: 14 })) }, `item-error-${value}`))] }));
};
const SelectButtonContainer = styled('div', { shouldForwardProp: prop => isPropValid(prop) })(props => ({
    display: 'flex',
    flexDirection: 'column',
    gap: props.theme.spacing_sizes.xs * 1.25,
    width: props.fullWidth ? '100%' : 'auto',
}));
const SelectButtonComponent = ({ options: initOptions = [], variant = 'default', onChange = undefined, onDelete = undefined, fullWidth = undefined, showDeleteBtn = undefined, value: componentValue = '', multiple = false, error = undefined, }, ref) => {
    const [options, updateOptions] = useState(() => defineInitValues(initOptions, componentValue));
    const handleItemClick = (value) => {
        const { selectedOptionsValues, newOptions, } = updateOptionsState(options, value, multiple);
        updateOptions(newOptions);
        if (onChange && multiple === false) {
            onChange(value);
        }
        else if (onChange) {
            onChange(selectedOptionsValues);
        }
    };
    const handleItemDelete = (value) => {
        updateOptions((prevOptions) => {
            const newState = Object.assign({}, prevOptions);
            if (newState[value]) {
                newState[value] = Object.assign(Object.assign({}, newState[value]), { selected: false });
            }
            return Object.assign({}, newState);
        });
        if (onDelete) {
            onDelete(value);
        }
    };
    useEffect(() => {
        updateOptions((prevOptions) => defineComponentValue(prevOptions, componentValue));
    }, [componentValue, error]);
    return (_jsx(SelectButtonContainer, { ref: ref, fullWidth: fullWidth, children: Object.keys(options).map((optionKey) => (_createElement(Item, Object.assign({}, options[optionKey], { itemVariant: variant, key: options[optionKey].value, onClick: handleItemClick, onDelete: handleItemDelete, showDeleteBtn: showDeleteBtn, fullWidth: fullWidth, hasError: Boolean((error && error.length > 0) && (options[optionKey].selected)), errorText: options[optionKey].selected ? error : undefined, multiple: multiple })))) }));
};
const SelectButton = forwardRef(SelectButtonComponent);
export default SelectButton;
